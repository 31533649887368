
import {computed, defineComponent, ref} from "vue";
import {
  alertController,
  IonBackButton,
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCol,
  IonContent,
  IonFab,
  IonFabButton,
  IonGrid,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonPage,
  IonRow, IonTitle,
  IonToolbar,
  isPlatform,
  onIonViewDidEnter,
  onIonViewWillEnter, onIonViewWillLeave


} from "@ionic/vue";
import {useStore} from "vuex";
import router from "@/app/router";
import {useRoute} from "vue-router";
import {EntryDelivery} from "@/domain/model/delivery/delivery.model";
import DetailsEntryDeliveryItemComponent from "@/app/view/delivery/details/DetailsEntryDeliveryItemComponent.vue";
import UpdateEntryDeliveryItemComponent from "@/app/view/delivery/details/UpdateEntryDeliveryItemComponent.vue";
import CreateEntryDeliveryItemComponent from "@/app/view/delivery/create/CreateEntryDeliveryItemComponent.vue";
import {
  CreateEntryDelivery,
  UpdateEntryDelivery,
  updateEntryDeliveryFromEntity
} from "@/domain/command/delivery/delivery.command";
import {uuid4} from "@capacitor/core/dist/esm/util";
import _ from "lodash";
import {useDeliveryApi} from "@/domain/service/api/delivery.api";
import {useViewStateApi} from "@/domain/service/api/viewState.api";
import {addOutline, sendOutline} from "ionicons/icons";

export default defineComponent({
  name: "DetailsEntryDelivery",
  components: {
    CreateEntryDeliveryItemComponent,
    IonPage,
    IonGrid,
    IonRow,
    IonCol,
    IonContent,
    IonItem,
    IonLabel,
    IonInput,
    IonButton,
    IonIcon,
    IonFab,
    IonFabButton,
    IonBackButton,
    IonToolbar,
    IonButtons,
    IonHeader,
    IonTitle,
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const id = ref(route.params.id);
    const isTablet = computed<boolean>(() => !isPlatform('desktop'));
    const {updateEntryDeliveryBeforeCheck} = useViewStateApi();
    const entryDelivery = computed<EntryDelivery>(() => store.getters.entryDeliveryById(id.value));
    const updateCommand = ref<UpdateEntryDelivery>(updateEntryDeliveryFromEntity(entryDelivery.value));
    const deliveryIndex = ref<string>(updateCommand.value.indexPosition);
    const originalIndex = ref<string>(deliveryIndex.value);
    const filterValidItems = (items: Array<any>) => _.reject(items, (item) => item.reference === '');
    const isAtLeastOneItem = computed<boolean>(() => filterValidItems(updateCommand.value.items).length > 0);
    const onIndexIsNotFree = async () => {
      const alert = await alertController
          .create({
            cssClass: 'my-custom-class',
            header: 'Alerta',
            subHeader: 'Número de albarán de entrada inválido',
            message: 'El número de albarán de entrada que ha introducido ya existe o está vacío, introduzca uno distinto',
            buttons: [
              {
                text: 'Ok',
                role: 'success',
                cssClass: 'danger',
              },
            ],
          });

      await alert.present();

      const {role} = await alert.onDidDismiss();
    }

    const onSendToCheck = async () => {
      const newCommand: UpdateEntryDelivery = updateCommand.value;
      newCommand.items = filterValidItems(newCommand.items);
      if (!deliveryIndex.value) {
        await onIndexIsNotFree();
        return;
      }

      if (deliveryIndex.value !== originalIndex.value) {
        await useDeliveryApi().isIndexUsed(_.cloneDeep(deliveryIndex.value));
        if (!store.getters.getIsThisIndexFree) {
          await onIndexIsNotFree();
          return;
        } else
          newCommand.indexPosition = _.cloneDeep(deliveryIndex.value);
      }
      updateEntryDeliveryBeforeCheck(newCommand);
      await router.push({name: `checkUpdateEntryDelivery`, params: {id: newCommand.id}});
    };

    const goBack = async () => {
      await router.back();
    };
    const addEmptyItem = () => {
      updateCommand.value.items.push({
        receivedAmount: 0, expectedAmount: 0, reference: '', id: uuid4(),
      });
    };
    const onNextItem = () => {
      addEmptyItem();
    }
    const onDeleteItem = (index: number) => {
      if (index > -1)
        updateCommand.value.items.splice(index, 1);
    }


    onIonViewWillEnter(() => {
      if (id.value === route.params.id)
        return;
      id.value = route.params.id;
      updateCommand.value = updateEntryDeliveryFromEntity(entryDelivery.value);
    })
    return {
      isAtLeastOneItem,
      goBack,
      entryDelivery,
      isTablet,
      updateCommand,
      onNextItem,
      onDeleteItem,
      addEmptyItem,
      onSendToCheck,
      deliveryIndex,
      addOutline,
      sendOutline,
    };
  }
})
